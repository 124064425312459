import React, { PureComponent } from 'react';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import {
  SinglePropertyHeaderSlider,

  DevelopmentPropertyContent,
  SinglePropertyAdvice,
  SinglePropertyPlots,
  SinglePropertySimilarSliderbyID,
  SinglePropertyMap,
  DevelopmentsHeader,
  DevelopmentsPricing,
  HomeGetAdvice

} from 'components/sections';

import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import request from 'utils/request';
import {singlePageMotion, functionHeroAniamtion, functionSectionLoad1, functionSectionLoad2, functionSectionLoad3 } from 'motion/single-property.js';
import { functionGetAdvice } from 'motion/new-homes.js';

import { ViewportEnterAnimation, Button, NewHomesHomeBadge } from 'components/ui';

import { DevelopmentDetailHighlights, toggleStampDutyModal } from 'components/blocks';

import { functionSlider } from 'motion/new-homes.js';
import { functionSinglePropertyAdvice } from 'motion/buy.js';

import bp from 'config/responsive';
import $ from 'jquery';


const mockData = {

  similarPropertiesText: {
    title: 'Availability',
    description: 'Other available properties at this development.',
  },
  contactCta: {
    left: {
      title: 'Join the team',
      content: 'Do you think you have what it takes to be part of our award winning team?',
    },
    right: {
      title: 'Refer a friend',
      content: 'Receive a reward for referring your friends and family members to our trusted property managers',
    },
  },
}

const Loading = styled.div`
    opacity: 1;
    position: fixed;
    z-index: 3;
    background: #1f2d52;
    width: 100%;
    height: 100%;
`

const LoadingOverlay = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #D9A547;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    animation: pulse 1s infinite ease;
  }

  @keyframes pulse {
    0%   { opacity: 0; transform: scale(0.2) }
    50% { opacity: 1; transform: scale(1.5) }
    100% { opacity: 0; transform: scale(0.2)}
  }
`

const FullSlide = styled.div`
  &.carousel-with-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;

    .hideonMobile {
      display: none !important;
    }

    ${bp.smallNotebook} {
      flex-direction: row;
    }

    & .slick-slider {
       width: 100%;

       ${bp.smallNotebook} {
       width: 100%;
      }
    }
    & .slick-prev {
      top: 0;
      bottom: 0;
      margin: auto;
    }
    & .slick-next {
      right: 11px;
      left: inherit !important;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
`
const FullSlideInner = styled.div`
  position: relative;
  width: 100%;

  ${bp.smallNotebook} {
    width: 60%;
   }
   ${bp.bigNotebook} {
    width: 70%;
  }
`
const FullSlideInfo = styled.div`
  width: 100%;

  & > div {
    padding-top: 0px;
  }

  ${bp.smallNotebook} {
    width: 40%;
    min-width: 415px;

    & > div {
      padding-top: 70px;
    }
  }

  ${bp.bigNotebook} {
    width: 30%;
  }
`

const ButtonsGroup = styled.div`

  position: absolute;
  z-index: 9;
  left: 0;
  bottom: 1.5rem;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & button, & a {
    &:not(:last-child) {
      margin-right: .5rem !important;
    }
      padding: 0px 10px !important;
    height: 46px !important;
    font-size: 14px !important;

    background: #0B1D3F !important;
    color: white !important;

    ${bp.smallNotebook} {
      padding: 0px 20px !important;
        height: 56px !important;
      font-size: 16px !important;
      }

    &.active {
      background: #335eb3 !important;
      color: white !important;
    }
  }

   ${bp.smallNotebook} {
     left: 1.5rem;
    right: 1.5rem;
    bottom: 3rem;
   }
`

const ButtonPhotos = styled(Button)`

  display: flex;

   ${bp.smallNotebook} {

   }
`
const ButtonLocation =styled(Button)`

  display: flex;

   ${bp.smallNotebook} {

   }
`

const mockDataAdvice = {
   helptobuy: {
    title: 'Help to Buy',
    content: 'The government has created the Help to Buy scheme to help you buy your own home with just a 5% deposit.',
    buttonText: 'Find out more',
  }
}

class Developments extends PureComponent {
  state = {
    data: {
      similarProperties: [],
      map: { center: {} },
      content: {},
      mainInfo: '',
      highlights: { keyFeatures: [], ctaPhoneNumber: '' },
      sliderPhotos: [],
      seo : {}
    },
    loading: true,
    error: false,
    isStampDutyOpen: false,


  };
  cleanTrailingSlash(site){     
    return site.replace(/\/$/, "");
  }

  getData = async () => {
    try {

      this.setState({ loading: true, error: false });
      var url = this.cleanTrailingSlash(this.props.location.pathname);

      var realID = (url.substring(url.lastIndexOf('/') + 1));

      //console.log(queryString.parse(this.props.location.search));

      const  id  = "rps_ebi-CAN201204"//queryString.parse(this.props.location.search);

      const property = await request(`developments/${realID}`);

      //const property = await request(`single-property/${realID}`);
      const similarProperties = '';


      //const similarProperties = await request(`similar-properties/${id}`);
      this.setState({
        loading: false,
        data: {
          ...property.data,
          similarProperties: similarProperties.data,
        },
      });

    } catch (err) {
      this.setState({ loading: false, error: true });

    }
  };

  async componentDidMount() {
    this.getData();
  }

  toggleStampDutyModal = () => {
    const currentState = this.state.isStampDutyOpen;
    const nextState = !currentState;

    if (nextState) {
      window.closeModal && window.closeModal();
      window.closeModal = this.toggleStampDutyModal;
    } else {
      window.closeModal = undefined;
    }

    this.setState({ isStampDutyOpen: nextState });
  }

  ctaClick = () => {
    if (typeof window !== 'undefined') {
      window.openEnquiry && window.openEnquiry();
    }
  }

  ctaTogglegallery = (e) => {

    $('.togglegallery').fadeIn();
    $('.maptoggler').hide();
    $('.button_slider').removeClass('active');

    $(e.target).addClass('active');
    
  }
    ctaTogglemap = (e) => {

    $('.togglegallery').hide();
    $('.maptoggler').fadeIn();

    $('.button_slider').removeClass('active');
    $(e.target).addClass('active');

  }

  render() {
    const { data, loading, isStampDutyOpen } = this.state;
    const {
      getAdvice,
      similarProperties,
      similarPropertiesText,
      sliderPhotos,
      mainInfo,
      pricing,
      pricingAndAvailabilityText,
      pricingAndAvailabilityData,
      content,
      map,
      pricing_link,
      seo
      
    } = data;

    console.log(seo);

    const center = {
      lat : map.localLifelat,
      lng : map.localLifelng,
     }


    return (
      <Layout onVisible={singlePageMotion}>

        <SEO title={seo.seo_title} description={seo.seo_meta} image={sliderPhotos[0]} metaRobots={seo.seo_robots} />

        {loading
          ? (

          <Loading className="single-loading">
            <LoadingOverlay></LoadingOverlay>
          </Loading>
           
          )
          : (

            <React.Fragment>

              <FullSlide className="carousel-with-info">
        <FullSlideInner>
          <ButtonsGroup>


            <ButtonPhotos className='button_slider active' onClick={this.ctaTogglegallery}><i class="camera icon"></i>Photos</ButtonPhotos>

            <ButtonLocation className='button_slider' onClick={this.ctaTogglemap}><i class="map marker alternate icon"></i>Location</ButtonLocation>


        </ButtonsGroup>

       <SinglePropertyHeaderSlider cover="cover" mobile="cover" photoList={sliderPhotos} />



        {map && <SinglePropertyMap {...map} />}
        </FullSlideInner>

        <FullSlideInfo className="content-info">

                  <DevelopmentDetailHighlights {...content} 
                  ctaPhoneNumber="0203 621 8881" 
                  title = {mainInfo.title+', '+mainInfo.address}
                  price = {mainInfo.starting_price}
                  content = {content}
                  mainInfo = {mainInfo}

                  short_description = {content.summary.ground_rent}


                  toggleStampDutyModal={toggleStampDutyModal} />


          </FullSlideInfo>
      </FullSlide>




            

              <DevelopmentPropertyContent {...content} />

             
              <ViewportEnterAnimation offset={100} animation={functionSlider}>
              </ViewportEnterAnimation>

              <ViewportEnterAnimation offset={100} animation={functionSlider}>
                <SinglePropertySimilarSliderbyID
                  content={mockData.similarPropertiesText} type="newdevelopments" string={mainInfo.title}
                />
              </ViewportEnterAnimation>


              <ViewportEnterAnimation offset={100} animation={functionSinglePropertyAdvice}>
                {getAdvice && <SinglePropertyAdvice onClick={this.ctaClick} content={getAdvice} />}
              </ViewportEnterAnimation>
            </React.Fragment>
            )
          
        }
      </Layout>



    );
  }
}

export default Developments;
